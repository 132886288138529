import React from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styles from "./AdminNavBar.module.css";
import { useAdmin } from "../../context/AdminContext/AdminContext";
import AdminTalentView from "../AdminTalentView/AdminTalentView";

const AdminNavBar = () => {
  const { logout } = useAdmin();
  const navigate = useNavigate();
  const location = useLocation();
  // const isModalOpen = location.pathname.includes("/t/");
  // const { talentId } = useParams();
  const [searchParams] = useSearchParams();
  const talentId = searchParams.get("talentId");

  const buttons = [
    { label: "Dashboard", path: "/admin-dashboard" },
    { label: "Matching-Tool", path: "/admin-matching-tool" },
    { label: "Talente", path: "/admin-talents" },
    { label: "Stellenanzeigen", path: "/admin-job-offers" },
    { label: "Unternehmen", path: "/admin-companies" },
  ];

  return (
    <>
      <div className={styles.page}>
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            <div className={styles.headerlogo}>
              <h5>globemee</h5>
              <p
                className="text-xs-medium"
                style={{ textAlign: "right", color: "var(--orange-80)" }}
              >
                ADMINPORTAL
              </p>
            </div>
            {buttons.map((button, index) => (
              <div
                key={index}
                className={`${styles.headerBtn} ${
                  location.pathname === button.path && styles.selectedBtn
                }`}
                onClick={() => navigate(button.path)}
              >
                {button.label}
              </div>
            ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => logout()}
            >
              <i className="material-symbols-outlined">logout</i>
              <p variant="body2" color="inherit">
                Logout
              </p>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <Outlet />
          {talentId && <AdminTalentView talentId={talentId} />}
        </div>
      </div>
    </>
  );
};
export default AdminNavBar;
