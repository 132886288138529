import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AdminTalentView.module.css";
import axiosInstance from "../../lib/axios/AxiosConfig";
import { useAdmin } from "../../context/AdminContext/AdminContext";
import DropdownWithSearch from "../../components/DropdownWithSearch/DropdownWithSearch";
import Modal from "../../components/Modal/Modal";

const AdminTalentView = ({ talentId }) => {
  const { jobOffers } = useAdmin();
  const navigate = useNavigate();
  const [talentData, setTalentData] = useState();
  const [selectedJobOffer, setSelectedJobOffer] = useState("");

  useEffect(() => {
    if (talentId) {
      getTalent();
    }
  }, [talentId]);

  const getTalent = async () => {
    try {
      const response = await axiosInstance.get(`a_talent/one/${talentId}`);
      setTalentData(response.data);
    } catch (error) {}
  };

  const closeModal = () => {
    navigate(-1);
  };

  if (!talentData) {
    return <>Loading...</>;
  }

  const matchTalent = async (talentUserId, jobOfferId) => {
    try {
      const response = await axiosInstance.post(`a_match/match`, {
        talentUserId: talentUserId,
        jobOfferId: jobOfferId,
      });
      console.log(response.data);
    } catch (error) {}
  };

  // console.log(talentData);

  return (
    <div className={styles.modalBackground} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <h3>{`${talentData.name} ${talentData.surname}`}</h3>

        {/* Match Talent */}
        <div className="row space-between gap-32">
          <h5>Match Talent</h5>
          <DropdownWithSearch
            options={jobOffers}
            display={"hardFacts.jobDescription.jobTitle"}
            onSelect={(value) => setSelectedJobOffer(value)}
            selectedValue={selectedJobOffer}
          />
          <button
            type="button"
            onClick={() => matchTalent(talentId, selectedJobOffer.jobOfferId)}
            className="primary medium"
          >
            Match Talent
          </button>
        </div>

        {/* Contact Information */}
        <div className="row gap-16">
          <div className="column gap-16">
            <p>
              <span className="text-m-semibold">Mail:</span>{" "}
              {talentData.userEmail}
            </p>
            <p>
              <span className="text-m-semibold">Nationalität:</span>{" "}
              {talentData.nationality.join(", ")}
            </p>
            <p>
              <span className="text-m-semibold">Anmeldung:</span>{" "}
              {talentData.signUpDate}
            </p>
            <p>
              <span className="text-m-semibold">Status:</span>{" "}
              {talentData.talentStatus}
            </p>
            <p>
              <span className="text-m-semibold">Priorisierung:</span>{" "}
              {talentData.priority}
            </p>
          </div>
          <div className="column gap-16">
            <p>
              <span className="text-m-semibold">Phone:</span> {talentData.phone}
            </p>
            <p>
              <span className="text-m-semibold">Wohnort:</span>{" "}
              {talentData.placeOfLiving}
            </p>

            <p>
              <span className="text-m-semibold">Quelle:</span>{" "}
              {talentData.referralSource}
            </p>
          </div>
        </div>

        {/* Career */}

        {talentData.curriculumVitae.workExperience.length > 0 && (
          <div className="column">
            {talentData.curriculumVitae.workExperience.map((item, index) => (
              <div key={index}>
                <div className="column">
                  <div className="row space-between">
                    <p className="text-m-semibold">{item.position}</p>
                    <p className="text-m-regular">
                      {item.startDate} -
                      {item.untilToday ? " today" : ` ${item.finishDate}`}
                    </p>
                  </div>
                  <p className="text-m-regular">{item.companyName}</p>
                  <p className="text-m-regular">{item.location}</p>
                </div>
                {talentData.curriculumVitae.workExperience.length >
                  index + 1 && <hr className={styles.hr} />}
              </div>
            ))}
          </div>
        )}
        {/* Education */}
        {talentData.curriculumVitae.education.length > 0 && (
          <div className="column minGap">
            <h5>Ausbildung</h5>
            <div className="column">
              {talentData.curriculumVitae.education.map((item, index) => (
                <div key={index}>
                  <div className="column">
                    <div className="row space-between">
                      <p className="text-m-semibold">{item.fieldStudy}</p>
                      <p className="text-m-regular">
                        {item.startDate} -
                        {item.untilToday ? " today" : ` ${item.finishDate}`}
                      </p>
                    </div>
                    <p className="text-m-regular">{item.nameUniversity}</p>
                    <p className="text-m-regular">
                      {item.city}, {item.country}
                    </p>
                  </div>
                  {talentData.curriculumVitae.education.length > index + 1 && (
                    <hr className={styles.hr} />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {/* apprenticeship */}
        {talentData.curriculumVitae.education.length > 0 && (
          <div className="column minGap">
            <h5>Apprenticeship</h5>
            <div className="column">
              {talentData.curriculumVitae.apprenticeship.map((item, index) => (
                <div key={index}>
                  <div className="column">
                    <div className="row space-between">
                      <p className="text-m-semibold">{item.position}</p>
                      {/* <p className="text-m-regular">
                              {item.startDate} -
                              {item.untilToday
                                ? " today"
                                : ` ${item.finishDate}`}
                            </p> */}
                    </div>
                    <p className="text-m-regular">{item.nameUniversity}</p>
                  </div>
                  {talentData.curriculumVitae.apprenticeship.length >
                    index + 1 && <hr className={styles.hr} />}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Language Skills */}
        <div className={styles.languageSkills}>
          <h3>Languages</h3>
          {talentData.career.language.map((lang) => (
            <div key={lang.language}>
              <p>
                {lang.language}: Talent Level - {lang.level}, Reviewed Level -{" "}
                {lang.reviewedLevel}
              </p>
            </div>
          ))}
        </div>

        {/* Personal Interests */}
        <div className={styles.personalInterests}>
          <h3>Personal Interests</h3>
          {talentData.personalInterests.map((interest, index) => (
            <p key={index}>{interest}</p>
          ))}
        </div>

        {/* Additional Information */}
        <div className={styles.additionalInfo}>
          <h3>Additional Information</h3>
          <p>
            <strong>Professional Challenge:</strong>{" "}
            {talentData.professionalChallenge}
          </p>
          <p>{/* <strong>Comments:</strong> {talentData.comments} */}</p>
        </div>

        {/* Attachments */}
        <div className={styles.attachments}>
          <h3>Attachments</h3>
          {talentData.documents.cv.map((doc) => (
            <div key={doc.documentId}>
              <a href={`/download/${doc.documentId}`}>{doc.name}</a>
            </div>
          ))}
          {talentData.documents.additional.map((doc) => (
            <div key={doc.documentId}>
              <a href={`/download/${doc.documentId}`}>{doc.name}</a>
            </div>
          ))}
        </div>

        <button onClick={closeModal}>Закрыть</button>
      </div>
      <Modal />
    </div>
  );
};

export default AdminTalentView;
