import React, { useState } from "react";
import styles from "./AdminMatchingTool.module.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import LanguageSelect from "../../../components/languageSelect/languageSelect";
import WormSelect from "../../../components/WormSelect";
import workLevel from "../../../common/workLevel";
import ItemList from "../../../components/ItemList/ItemList";
import SalaryInput from "../../../components/SalaryInput/SalaryInput";
import axiosInstance from "../../../lib/axios/AxiosConfig";
import { addGlobalNotification } from "../../../context/ModalContext/ModalContext";
import IscoSearch from "../../../components/IscoSearch/IscoSearch";
import BubbleList from "../../../components/BubbleList/BubbleList";
import Language from "../../../common/Language";
import { useNavigate } from "react-router-dom";
// import visa from "../../../common/visa";
// import relocate from "../../../common/relocate";

function AdminMatchingTool() {
  const [talentList, setTalentList] = useState([]);
  const [talentVisible, setTalentVisible] = useState(true);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      mustHaveSkills: [],
      commonSkills: [],
      jobTitle: [],
      experience: 0,
      language: [
        {
          language: "Englisch",
          level: "0",
        },
        {
          language: "Deutsch",
          level: "0",
        },
      ],
      industry: [],
      minSalary: 10000,
      maxSalary: 200000,
      visa: null,
      educationalConfirmation: null,
      willingnessToRelocate: null,
      interestedApprenticeship: false,
      isco_code: [],
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    register,
    watch,
  } = methods;

  const visa = [
    { name: "Ja", text: "Yes" },
    { name: "Nein", text: "No" },
    { name: "EU-Passport", text: "I have an EU Passport" },
  ];

  const educationConfirm = [
    { name: "Ja", text: "Yes" },
    { name: "Nein", text: "No" },
    { name: "Nicht sicher", text: "I don't know" },
  ];

  const relocate = [
    { name: "Ja", text: "Yes" },
    { name: "Nein", text: "Just remote" },
    { name: "Bereits in DE", text: "I already live in Germany" },
    { name: "Alle", text: "Both" },
  ];

  const onSubmit = async (data) => {
    data.isco_code = data.isco_code.map((item) => item.code);
    try {
      const response = await axiosInstance.post(`m_match/match`, data);
      if (Array.isArray(response.data)) {
        setTalentList(response.data);
        addGlobalNotification(
          `${response.data.length} Datensätze gefunden`,
          "success"
        );
      } else {
        setTalentList([]);
        addGlobalNotification("Nichts gefunden", "warning");
      }
    } catch (error) {}
  };

  const SortableTable = () => {
    const [data, setData] = useState(talentList);
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

    const handleSort = (key) => {
      let direction = "asc";
      if (sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }

      const sortedData = [...data].sort((a, b) => {
        const valueA = getValueByKey(a, key);
        const valueB = getValueByKey(b, key);

        if (valueA < valueB) {
          return direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      });

      setData(sortedData);
      setSortConfig({ key, direction });
    };

    const getValueByKey = (item, key) => {
      switch (key) {
        case "score":
          return item.score;
        case "priority":
          return item.priority;
        case "name":
          return item.name;
        case "careerTitle":
          return item.career.careerTitle;
        case "language":
          // Сортируем по первому языку, например
          return item.career.language
            .map((lang) => `${lang.language} (Level ${lang.level})`)
            .join(", ");
        case "experience":
          return item.career.experience;
        case "educationalConfirmation":
          return item.visaInfo.educationalConfirmation;
        case "visa":
          return item.visaInfo.visa;
        default:
          return ""; // на случай, если ключ не совпадает
      }
    };

    return (
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th
                className={styles.table_score}
                onClick={() => handleSort("score")}
              >
                Score
              </th>
              <th
                className={styles.table_prio}
                onClick={() => handleSort("priority")}
              >
                Prio
              </th>
              <th
                className={styles.table_name}
                onClick={() => handleSort("name")}
              >
                Name
              </th>
              <th
                className={styles.table_title}
                onClick={() => handleSort("careerTitle")}
              >
                Fachrichtung
              </th>
              <th
                className={styles.table_lang}
                onClick={() => handleSort("language")}
              >
                Sprachskill
              </th>
              <th
                className={styles.table_exp}
                onClick={() => handleSort("experience")}
              >
                Erfahrung
              </th>
              <th
                className={styles.table_edu}
                onClick={() => handleSort("educationalConfirmation")}
              >
                Anerk. Abschluss
              </th>
              <th
                className={styles.table_visa}
                onClick={() => handleSort("visa")}
              >
                Visum
              </th>
              <th className={styles.table_btn}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.talentUserId}>
                <td className={styles.cell50}>{item.score}</td>
                <td className={styles.cell50}>{item.priority}</td>
                <td className={styles.cellfree}>{item.name}</td>
                <td className={styles.cellfree}>{item.career.careerTitle}</td>
                <td className={styles.cell100}>
                  {item.career.language
                    .map(
                      (lang) =>
                        `${lang.language} (${
                          Language.find((level) => level.level === lang.level)
                            .short
                        })`
                    )
                    .map((langText, index) => (
                      <span key={index}>
                        {langText}
                        <br />
                      </span>
                    ))}
                </td>
                <td className={styles.cell100}>
                  {
                    workLevel.find(
                      (level) => level.text === item.career.experience
                    ).shortDe
                  }
                </td>
                <td className={styles.cell100}>
                  {item.visaInfo.educationalConfirmation}
                </td>
                <td className={styles.cell100}>{item.visaInfo.visa}</td>
                <td>
                  <button
                    onClick={() => navigate(`?talentId=${item.talentUserId}`)}
                    className="primary circle"
                  >
                    <i
                      className="material-symbols-outlined"
                      style={{ fontSize: "16px" }}
                    >
                      arrow_forward_ios
                    </i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="column gap-32">
      <h2>Matching Tool</h2>
      <div className={styles.filterWrapper}>
        <div className="row gap-32">
          <h3>Suchparameter</h3>
          <button
            className="secondary small"
            type="button"
            onClick={() => reset()}
          >
            Filter zurücksetzen
          </button>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.filterContainer}>
              <div className={styles.fiterColumn}>
                {/* MUST HAVE SKILLS */}
                <div className="content-part">
                  <p className="text-m-semibold">Must Have Skills</p>
                  <Controller
                    name="mustHaveSkills"
                    control={control}
                    render={({ field }) => (
                      <ItemList
                        type="simpleList"
                        searchType="server"
                        value={field.value}
                        onChange={field.onChange}
                        address={"common_source/get_updated_skills"}
                        listName={"mustHaveSkills"}
                        reqParam={"language=de"}
                        manual="neue Auswahl: "
                      />
                    )}
                  />
                </div>

                {/* SKILLS */}
                <div className="content-part">
                  <p className="text-m-semibold">Weitere Skills</p>
                  <Controller
                    name="commonSkills"
                    control={control}
                    render={({ field }) => (
                      <ItemList
                        type="simpleList"
                        searchType="server"
                        value={field.value}
                        onChange={field.onChange}
                        address={"common_source/get_updated_skills"}
                        listName={"commonSkills"}
                        reqParam={"language=de"}
                        manual="neue Auswahl: "
                      />
                    )}
                  />
                </div>

                {/* JOB TITLE */}
                <div className="content-part">
                  <p className="text-m-semibold">Jobtitel</p>
                  <Controller
                    name="jobTitle"
                    control={control}
                    render={({ field }) => (
                      <ItemList
                        type="simpleList"
                        searchType="server"
                        value={field.value}
                        onChange={field.onChange}
                        address={"common_source/get_job_titles"}
                        listName={"jobTitle"}
                        reqParam={"language=de"}
                        manual="neue Auswahl: "
                      />
                    )}
                  />
                </div>

                {/* ISCO SEARCH */}
                <div className="content-part">
                  <p className="text-m-semibold">
                    Berufsklassifikation (nach ISCO-08)
                  </p>

                  <Controller
                    name="isco_code"
                    control={control}
                    render={({ field }) => (
                      <IscoSearch
                        selectedValue={field.value}
                        onSelect={(value) => {
                          if (!field.value.includes(value)) {
                            field.onChange([...field.value, value]);
                          }
                        }}
                      />
                    )}
                  />

                  <BubbleList
                    list={watch("isco_code")}
                    onDelete={(value) => {
                      const currentList = getValues("isco_code");
                      const updatedList = currentList.filter(
                        (item) => item !== value
                      );
                      setValue("isco_code", updatedList);
                    }}
                    display={"titleEN"}
                  />
                </div>

                {/* EXPERIENCE */}
                <div className="content-part">
                  <p className="text-m-semibold">
                    Gewünschte Berufserfahrung in Jahren
                  </p>
                  <Controller
                    name="experience"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <WormSelect
                        options={[
                          {
                            name: "Beliebig",
                            text: 0,
                          },
                          ...workLevel,
                        ]}
                        onChange={field.onChange}
                        listName="experience"
                        value={field.value}
                        error={errors?.experience ? "true" : "false"}
                      />
                    )}
                  />
                </div>

                {/* LANGUAGE */}
                <div className="content-part">
                  <p className="text-m-semibold">Sprachkenntnisse</p>
                  <Controller
                    control={control}
                    name="language"
                    rules={{
                      validate: (languages) => {
                        const isValid = languages.every(
                          (lang) => lang.language.trim() !== ""
                        );
                        return isValid || "All languages must be filled in";
                      },
                    }}
                    render={({ field }) => (
                      <LanguageSelect
                        language="de"
                        onChange={field.onChange}
                        knowledgeList={field.value}
                      />
                    )}
                  />
                  {errors?.language?.message && (
                    <p className="error">{errors?.language?.message}</p>
                  )}
                </div>
              </div>
              <div className={styles.fiterColumn}>
                {/* INDUSTRY */}
                <div className="content-part">
                  <p className="text-m-semibold">Branchen</p>
                  <Controller
                    name="industry"
                    control={control}
                    render={({ field }) => (
                      <ItemList
                        type="simpleList"
                        searchType="front"
                        value={field.value}
                        onChange={field.onChange}
                        address={"common_source/get_industries"}
                        listName={"industry"}
                        reqParam={"language=de"}
                      />
                    )}
                  />
                </div>

                {/* SALARY */}
                <div className="content-part">
                  <p className="text-m-semibold">
                    Gehaltsspanne (Jährliches Bruttoeinkommen)
                  </p>
                  <SalaryInput varName={""} />
                </div>

                {/* EDUCATION CONFIRMATION */}
                <div className="content-part">
                  <p className="text-m-semibold">Abschluss anerkannt?</p>
                  <div className="radio_list">
                    {educationConfirm.map((item, index) => (
                      <div key={index} className="radio_item">
                        <input
                          className="real-radio flex"
                          type="radio"
                          id={`radio_educationalConfirmation_${index}`}
                          value={item.text}
                          {...register("educationalConfirmation")}
                        />
                        <span className="custom-radio"></span>
                        <label
                          htmlFor={`radio_educationalConfirmation_${index}`}
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* VISA */}
                <div className="content-part">
                  <p className="text-m-semibold">Visum vorhanden?</p>
                  <div className="radio_list">
                    {visa.map((item, index) => (
                      <div key={index} className="radio_item">
                        <input
                          className="real-radio flex"
                          type="radio"
                          id={`radio_visa_${index}`}
                          value={item.text}
                          {...register("visa")}
                        />
                        <span className="custom-radio"></span>
                        <label htmlFor={`radio_visa_${index}`}>
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* RELOCATION */}
                <div className="content-part">
                  <p className="text-m-semibold">Umzugsbereit?</p>
                  <div className="radio_list">
                    {relocate.map((item, index) => (
                      <div key={index} className="radio_item">
                        <input
                          className="real-radio flex"
                          type="radio"
                          id={`radio_willingnessToRelocate_${index}`}
                          value={item.text}
                          {...register("willingnessToRelocate")}
                        />
                        <span className="custom-radio"></span>
                        <label htmlFor={`radio_willingnessToRelocate_${index}`}>
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* APPRENTIENSHIP */}
                <div className="content-part">
                  <p className="text-m-semibold">Ausbildung</p>
                  <div className="start center">
                    <input
                      type="checkbox"
                      id="interestedApprenticeship"
                      {...register("interestedApprenticeship")}
                    />
                    <label htmlFor="interestedApprenticeship">
                      Talent ist an Ausbildung interessiert
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="end">
              <button className="primary medium" type="submit">
                Talente suchen
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <h3>Passende Talente</h3>
      <div className="row gap-32 center start">
        <h4>Platform</h4>
        <button
          className={`${talentVisible ? "secondary" : "primary"} small`}
          onClick={() => setTalentVisible(!talentVisible)}
        >
          {talentVisible ? "Ausblenden" : "Offenlegen"}
        </button>
      </div>
      {talentVisible && <SortableTable />}
      <h4>Jotform</h4>
      <div style={{ marginBottom: "500px" }} />
    </div>
  );
}

export default AdminMatchingTool;
