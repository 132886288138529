import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AdminDashboard.module.css";

function AdminDashboard() {
  const navigate = useNavigate();
  const routes = [
    { label: "Matching-Tool", path: "/admin-matching-tool" },
    { label: "Talente", path: "/admin-talents" },
    { label: "Stellenanzeigen", path: "/admin-job-offers" },
    { label: "Unternehmen", path: "/admin-companies" },
  ];

  return (
    <>
      <h2>Admin Portal</h2>
      <div className={styles.roadList}>
        {routes.map((item, index) => (
          <div
            className={styles.roadItem}
            key={index}
            onClick={() => navigate(item.path)}
          >
            <p className="text-m-semibold">{item.label}</p>
          </div>
        ))}
      </div>
    </>
  );
}

export default AdminDashboard;
