import React from "react";

const NotesForm = ({ onChange, value }) => {
  return (
    <>
      <p className="text-m-semibold">
        Anything you think we should need to know about you? Write us a note
        here (optional):
      </p>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Describe your professional challenge"
      />
    </>
  );
};

export default NotesForm;
