const visa = [
  {
    name: "Yes",
    text: "Yes",
  },
  {
    name: "No",
    text: "No",
  },
  {
    text: "I have an EU-Passport",
    name: "I have an EU-Passport",
  },
];

export default visa;
